<template>
  <div class="animated fadeIn">
    <add-person :label="'کارکنان خدمات'" :job="'servant'"></add-person>
  </div>
</template>
<script>
const addPerson = () => import("../../components/hr/AddPerson.vue");

export default {
  components: {
    addPerson
  },
};
</script>

